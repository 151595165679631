import React from 'react';
import PropTypes from 'prop-types';
import { spaces } from '@firsttable/web-theme';
import Row from '../../atoms/Row';
import Title from '../../atoms/Title';
import Col from '../../atoms/Col';
import Text from '../../atoms/Text';
import Box from '../../atoms/Box';
// @ts-ignore
import birdBook from './bird-with-book.svg';

const RowHead = ({
  title,
  children,
  subTitle,
  subTitleSize,
  titleSize,
  birdBookIcon,
  ...rest
}: {
  title?: string;
  children: any;
  subTitle?: string;
  subTitleSize?: number | string;
  titleSize?: number | string;
  birdBookIcon: any;
}) => (
  <Row textAlign="center" {...spaces(rest)}>
    {title ? (
      <Col>
        <Box maxWidth={birdBookIcon ? ['90%', '50%'] : null} mx="auto">
          <Title as="h1" size={titleSize} mb="s">
            {title}
          </Title>
          {subTitle && (
            <Text letterSpacing="-0.5px" fontSize={subTitleSize}>
              {subTitle}
            </Text>
          )}
        </Box>
        {birdBookIcon && (
          <Box position={[null, 'absolute']} top={0} left={80} mx="auto" my="s">
            <img src={birdBook} alt="Bird" />
          </Box>
        )}
      </Col>
    ) : (
      children
    )}
  </Row>
);

RowHead.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  subTitle: PropTypes.string,
  mb: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  subTitleSize: PropTypes.string,
  titleSize: PropTypes.string,
  birdBookIcon: PropTypes.bool,
};

RowHead.defaultProps = {
  title: '',
  subTitle: '',
  mb: ['10px', '50px'],
  subTitleSize: 'xl',
  titleSize: 'h1',
  children: null,
  birdBookIcon: false,
};

export default RowHead;

import React from 'react';
// import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import Row from '@firsttable/web-components/atoms/Row/Row';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import Col from '@firsttable/web-components/atoms/Col/Col';
import PostCard from '@firsttable/web-components/molecules/PostCard';
import Link from '@firsttable/web-components/atoms/Link';
import { awsImage, monthYear } from '@firsttable/functions';
import NavLinkComponent from '../components/atoms/NavLink';
import SEO from '../components/seo';
import ThemeLayout from '../layouts/layout';
import MainMenu from '../components/organisms/Navigation/Menu';
import Footer from '../components/organisms/Footer';

const Magazine = ({ data: { blogPosts, magazinePage } }) => {
  if (!magazinePage) {
    return <SEO meta={[{ 'http-equiv': 'refresh', content: '0;url=/' }]} />;
  }
  return (
    <ThemeLayout footer={<Footer />}>
      <SEO
        id={magazinePage.foreignId}
        title={magazinePage.metaTitleFormatted}
        description={magazinePage.metaDescription}
        ogImage={magazinePage.ogImage}
        metaTags={magazinePage.metaTags}
        slug={magazinePage.slug}
      />
      <MainMenu />
      <ContainerContent>
        <RowHead title="First Table Magazine" />
        <Row gap={40} mb={['-20px', '-40px']}>
          {blogPosts.edges
            .filter(({ blogPost }) => !blogPost?.hideFromMagazine)
            .map(({ blogPost }) => (
              <Col
                key={blogPost.foreignId}
                width={[1, 1 / 2, 1 / 3]}
                mb={['20px', '40px']}
              >
                <Link NavComponent={NavLinkComponent} to={blogPost.slug}>
                  <PostCard
                    title={blogPost.title}
                    description={blogPost.summary}
                    cover={[
                      awsImage(blogPost.heroImage, 450, 350),
                      awsImage(blogPost.heroImage, 450 * 2, 350 * 2),
                    ]}
                    date={monthYear(blogPost.publishDate)}
                    author={blogPost.authorNames || 'First Table'}
                  />
                </Link>
              </Col>
            ))}
        </Row>
      </ContainerContent>
    </ThemeLayout>
  );
};

Magazine.propTypes = {
  data: PropTypes.shape({
    hasBlogPosts: PropTypes.object,
    blogPosts: PropTypes.object,
    magazinePage: PropTypes.object,
  }).isRequired,
};
Magazine.defaultProps = {};

export default Magazine;

export const query = graphql`
  query getBlogPosts {
    blogPosts: allBlogPost {
      edges {
        blogPost: node {
          id
          foreignId
          slug
          title
          parentId
          hideFromMagazine
          metaTitleFormatted
          metaDescription
          hideFromMagazine
          menuTitle
          metaTags
          content
          className
          authorNames
          summary
          publishDate
          heroImage
          ogImage
        }
      }
    }
    magazinePage: page(slug: { eq: "/magazine/" }) {
      slug
      title
      foreignId
      className
      metaDescription
      metaTitleFormatted
      ogImage
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import Container from '../../atoms/Container';
import { BoxProps } from '../../atoms/Box/Box';

const ContainerContent = (props: BoxProps) => <Container {...props} />;

ContainerContent.propTypes = {
  mt: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  mb: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
};
ContainerContent.defaultProps = {
  mt: ['40px', '70px'],
  mb: ['40px', '90px'],
};

export default ContainerContent;

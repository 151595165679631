import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Flex from '../../atoms/Flex';
import Box from '../../atoms/Box';
import Title from '../../atoms/Title';
import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider/Divider';
import Avatar from '../../atoms/Avatar';

const Wrapper = styled(Box)`
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px ${themeGet('colors.grayscale.300')};
`;

type PostCardProps = {
  title: string;
  description: string;
  avatarUrl: string;
  cover: string | string[];
  date: string;
  author: string;
};
const PostCard = ({
  title,
  description,
  avatarUrl,
  cover,
  date,
  author,
}: PostCardProps) => {
  let BlogAvatar;
  if (avatarUrl) {
    BlogAvatar = (
      <img className="img-fluid rounded-circle" src={avatarUrl} alt="" />
    );
  } else {
    BlogAvatar = <Avatar color="brand.red" width="36px" height="36px" />;
  }

  return (
    <Wrapper>
      <Box
        className="bg-pulse-animation"
        height={[null, '270px']}
        width="100%"
        bg="grayscale.300"
        display={['inline-block', 'block']}
        verticalAlign="top"
        textAlign="center"
      >
        <LazyLoadImage
          srcSet={`${cover[0]} 1x, ${cover[1]} 2x`}
          src={cover[0]}
          alt={title}
          className="img-fluid"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Box>
      <Box p="24px">
        <Title size="h6">{title}</Title>
        <Text>{description}</Text>
        <Box mt="24px">
          <Flex alignItems="center" minHeight="40px">
            <Divider flex={1} />
            <Box width="40px" ml="24px">
              {BlogAvatar}
            </Box>
          </Flex>
          <Text fontSize="s" mb={0}>
            <Text as="strong">{author}</Text>
            &nbsp; • &nbsp;
            <Text as="span" color="grayscale.600">
              {date}
            </Text>
          </Text>
        </Box>
      </Box>
    </Wrapper>
  );
};

PostCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  avatarUrl: PropTypes.string,
  cover: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  date: PropTypes.string,
  author: PropTypes.string,
};
PostCard.defaultProps = {
  title: '',
  description: '',
  avatarUrl: '',
  cover: '',
  date: '',
  author: '',
};

export default PostCard;
